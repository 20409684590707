<template>
  <div class="set-system w-100_ h-100_">
    <div class="set-system-header d-f ai-c jc-sb">
      <span class="c-p" @click="goBack">
        <i class="el-icon-arrow-left"> </i> 返回上一页</span
      >
    </div>
    <div>
      <el-table border :data="sysTableList">
        <el-table-column width="250px" label="一级院系">
          <template slot-scope="scope">
            <span
              class="c-p"
              @click="changeOrgName(scope.row)"
              v-if="scope.row.orgName"
              >{{ scope.row.orgName }}</span
            >
            <el-button type="primary" @click="addAcadmy" v-else
              >+ 添加</el-button
            >
          </template>
        </el-table-column>
        <el-table-column label="二级院系">
          <template slot-scope="scope">
            <template v-for="(item, idx) in scope.row.subOrganizationList">
              <el-tag
                style="margin: 0 5px"
                class="c-p"
                v-if="!(item.student && item.student.length)"
                :key="item.orgName"
                closable
                type="info"
                @click="changDepartment(scope.row, idx)"
                @close="handleCloseTag(item, scope.$index)"
              >
                {{ item.orgName }}
              </el-tag>
              <span style="margin: 0 5px" :key="item.orgName" v-else>{{
                item.orgName
              }}</span>
            </template>
            <el-button
              v-if="scope.row.orgName"
              @click="addSystem(scope.row.id)"
              type="primary"
              >+ 添加</el-button
            >
          </template>
        </el-table-column>
        <el-table-column width="100px" align="center" label="操作">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.orgName"
              :disabled="false"
              type="text"
              @click="delDate(scope.row.id, scope.$index)"
            >
              <i class="el-icon-delete"></i
            ></el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
const { mapActions, mapState } = createNamespacedHelpers("setting");
import { getSchoolId, getOrgId } from "../common/store";
export default {
  name: "set-system",
  data() {
    return {
      // tableList: [
      //   {
      //     academy: "计算机学院",
      //     systems: [
      //       { name: "计算机科学", student: ["", ""] },
      //       { name: "软件工程", student: ["", ""] },
      //       { name: "人工智能", student: ["", ""] },
      //     ],
      //   },
      // ],
    };
  },
  methods: {
    ...mapActions(["getAccList", "addAcc", "deleteAcc", "modifyAcc"]),
    addAcadmy() {
      this.$prompt("请输入学院名称", "添加学院", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        this.addAcc({
          parentId: getOrgId(),
          universityId: getSchoolId(),
          orgName: value,
          orderNum: 0,
          remarks: "",
          subOrganizationList: [],
        });
      });
    },
    addSystem(id) {
      this.$prompt("请输入系名称", "添加系", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
      }).then(({ value }) => {
        this.addAcc({
          universityId: getSchoolId(),
          parentId: id,
          orgName: value,
          orderNum: 0,
          remarks: "",
          subOrganizationList: [],
        });
      });
    },
    handleCloseTag(item) {
      this.$confirm(
        "你确定要删除当前系(" + item.orgName + ")信息吗？",
        "系信息删除确认",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.deleteAcc(item.id);
      });
    },
    changeOrgName(row) {
      this.$prompt(
        "操作将会同步修改相关学生的院系信息",
        "修改学院名称(" + row.orgName + ")",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputType: "text",
          placeholder: "请填写新的学院名称",
        }
      ).then(({ value }) => {
        row.orgName = value;
        this.modifyAcc(row);
      });
    },
    changDepartment(row, idx) {
      const lsRow = JSON.parse(JSON.stringify(row));
      this.$prompt(
        "操作将会同步修改相关学生的院系信息",
        "修改系名称(" + lsRow.subOrganizationList[idx].orgName + ")",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          inputType: "text",
          placeholder: "请填写新的学院名称",
        }
      ).then(({ value }) => {
        lsRow.subOrganizationList[idx].orgName = value;
        this.modifyAcc(lsRow.subOrganizationList[idx]);
      });
    },
    delDate(id, index) {
      this.$confirm(
        "你确定要删除当前学院(" +
          this.tableList[index].orgName +
          ")及相关信息吗？",
        "学院信息删除确认",
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }
      ).then(() => {
        this.deleteAcc(id);
      });
    },
    goBack() {
      this.$router.push("/setting");
    },
  },
  mounted() {
    this.getAccList();
  },
  computed: {
    sysTableList() {
      return [...this.tableList, {}];
    },
    ...mapState({
      tableList: (state) => state.academyList,
    }),
  },
};
</script>

<style lang="scss" scoped>
.set-system {
  background: #f0f0f0;
  padding: 24px;
  .set-system-header {
    height: 60px;
    padding: 0 24px;
    background: #fff;
    margin: 10px 0;
  }
  .el-button--primary {
    background: rgba(247, 181, 0, 0.1);
    border-radius: 2px;
    border: 1px solid #fab987;
    font-size: 12px;
    font-family: PingFangSC-Regular, PingFang SC;
    font-weight: 400;
    color: #fa6400;
    padding: 4px 15px;
  }
}
</style>
