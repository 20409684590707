<template>
  <div class="w-100_ h-100_ d-f jc-c ai-c">免密登录中...</div>
</template>

<script>
import {
  changStore,
  setToken,
  setSchoolId,
  setOrgId,
  setAuthorization,
} from "../common/store";
export default {
  name: "mm",
  data() {
    return {};
  },
  mounted() {
    const { query } = this.$route;
    const { universityId, universityName, mmToken, orgId } = query;
    if ((universityId, universityName, mmToken, orgId)) {
      changStore(window.sessionStorage);
      setToken(universityName);
      setSchoolId(universityId);
      setAuthorization(mmToken);
      setOrgId(orgId);
			this.$router.push('/room')
    }
  },
};
</script>

<style>
</style>